<template>
    <v-dialog max-width="500" scrollable v-model="dialog" persistent>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-btn
            text
            color="grey"
            v-bind="attrs"
            v-on="{...menu }"
        >
          <v-icon left>mdi-tune</v-icon> Filter
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Filtrer par
          <v-spacer />
          <v-btn
            small
            outlined
            text
            class="mr-3 ml-3 no-anim"
            @click="clearFilter"
            >Effacer</v-btn
          >
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-8">
          <v-row>
            <v-col cols="12">
              <v-dialog
                ref="dialogStartDate"
                v-model="modalStartDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense
                    v-model="filter.startDate"
                    label="Date début"
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.startDate"
                  @input="[(filter.endDate = null), (modalStartDate = false)]"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12">
              <v-dialog
                ref="dialogEndDate"
                v-model="modalEndDate"
                @click="modalEndDate = false"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense
                                v-model="filter.endDate"
                    label="Date fin"
                    hide-details
                    :disabled="!filter.startDate"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="modalEndDate = false"
                  v-model="filter.endDate"
                  :min="filter.startDate"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-dialog
                ref="dialogSendDate"
                v-model="modalSendDate"
                @click="modalSendDate = false"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense
                                v-model="filter.sendDate"
                    label="Date d'envoi"
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="modalSendDate = false"
                  v-model="filter.sendDate"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>  
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="handleFilter"
          >
            Recherche
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
    
<script>

export default {
    data() {
      return {
        dialog: false,
        filter: {
          startDate: "",
          endDate: "",
          sendDate: ""
        },
  
        modalStartDate: false,
        modalEndDate: false,
        modalSendDate: false,
      };
    },
    methods: {
      handleFilter() {
        this.dialog = false;
        this.$emit("filter", this.filter);
    
      },
      clearFilter() {
        this.filter = {};
      },
    },
    computed: {
      isDisabled() {
        return Object.values(this.filter).every(
          (x) => x === null || x === "" || x.length === 0
        );
      },
      clients() {
        return this.$store.getters.getClients;
      },
    },
  };
  </script>
    