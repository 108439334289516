<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent scrollable>

      <form @submit.prevent="save" enctype="multipart/form-data">

        <v-card>

          <v-card-title>
            {{ item ? "Modifier une notification" : "Nouvelle notification" }}
            <v-spacer/>
            <v-btn icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider/>

          <v-card-text v-if="noticeLoading">
            <v-skeleton-loader type="article"/>
          </v-card-text>

          <v-card-text class="pa-10" v-else>


            <v-tabs v-model="language_tab" color="gifty" class="mb-3">
              <v-tab> Francais</v-tab>
              <v-tab> Arabe</v-tab>
            </v-tabs>

            <v-tabs-items v-model="language_tab">

              <v-tab-item>

                <v-text-field outlined
                              dense
                              label="Titre *"
                              @input="
                        formErrors && formErrors.title
                          ? (formErrors.title = '')
                          : false
                      "
                              :error-messages="
                        formErrors && formErrors.title
                          ? formErrors.title[0]
                          : ''
                      "
                              v-model="form.title"
                />

                <v-alert prominent text color="gifty">
                      <span class="font-weight-bold pointer">
                        <Copy text="@person"/>
                      </span>
                  Est remplacé par le nom de la personne
                </v-alert>

                <v-textarea dense
                            v-model.trim="form.body"
                            :error-messages="
                        formErrors && formErrors.body ? formErrors.body[0] : ''
                      "
                            @input="
                        formErrors && formErrors.body
                          ? (formErrors.body = '')
                          : false
                      "
                            clearable
                            label="Message *"
                            outlined
                            prepend-inner-icon="mdi-message-outline"
                            rows="3"
                >
                </v-textarea>

              </v-tab-item>

              <v-tab-item>

                <v-text-field dense outlined
                              label="Titre en Arabe*"
                              @input="
                        formErrors && formErrors.title_ar
                          ? (formErrors.title_ar = '')
                          : false
                      "
                              :error-messages="
                        formErrors && formErrors.title_ar
                          ? formErrors.title_ar[0]
                          : ''
                      "
                              v-model="form.title_ar"
                />

                <v-alert prominent text color="gifty">
                      <span class="font-weight-bold pointer">
                        <Copy text="@person"/>
                      </span>
                  Est remplacé par le nom de la personne
                </v-alert>

                <v-textarea dense
                            v-model.trim="form.body_ar"
                            :error-messages="
                        formErrors && formErrors.body_ar
                          ? formErrors.body_ar[0]
                          : ''
                      "
                            @input="
                        formErrors && formErrors.body_ar
                          ? (formErrors.body_ar = '')
                          : false
                      "
                            clearable
                            label="Message en arabe*"
                            outlined
                            prepend-inner-icon="mdi-message-outline"
                            rows="3"
                />

              </v-tab-item>
            </v-tabs-items>

            <div class="d-flex align-center">
              <v-file-input dense outlined
                            label="Image *"
                            accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                            show-size
                            hide-details="auto"
                            @change="uploadFile"
                            @focus="
                  formErrors && formErrors.image
                    ? (formErrors.image = '')
                    : false
                "
                            ref="file"
                            chips
                            prepend-icon="mdi-camera"
                            :error-messages="formErrors.image"
              />
              <v-btn
                  depressed
                  icon
                  small
                  class="gifty white--text ml-2"
                  v-if="form.image"
                  @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <div
                v-if="item && item.image"
                class="d-flex justify-space-between pa-3 mb-2 rounded"
                :class="
                $vuetify.theme.dark ? 'secondary lighten-1' : 'grey lighten-3'
              "
            >
              <v-avatar size="40">
                <v-img
                    :src="getImageLink(item.image)"
                    class="rounded zoom-pointer"
                />
              </v-avatar>
              <v-spacer/>
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

            <v-checkbox :hide-details="sendNow" label="Envoyer maintenant" v-model="sendNow"/>

            <v-row v-if="!sendNow">
              <v-col>
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense
                                  v-model="send_day"
                                  hide-details
                                  label="Date d'envoi *"
                                  prepend-inner-icon="mdi-calendar-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :min="send_day"
                      v-model="send_day"
                      @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <p
                    v-if="formErrors && formErrors.send_at"
                    class="error--text mt-0"
                >
                  {{ formErrors.send_at[0] }}
                </p>
              </v-col>
              <v-col>
                <v-menu
                    ref="menu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :return-value.sync="send_hour"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense
                                  v-model="send_hour"
                                  label="Heure d'envoi *"
                                  hide-details
                                  prepend-inner-icon="mdi-clock-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      @click:minute="$refs.menu.save(send_hour)"
                      v-model="send_hour"
                  ></v-time-picker>
                </v-menu>

                <p
                    v-if="formErrors && formErrors.send_at"
                    class="error--text mt-0"
                >
                  {{ formErrors.send_at[0] }}
                </p>
              </v-col>
            </v-row>

            <v-checkbox
                hide-details="auto"
                label="Pour tout le monde"
                v-model="form.is_default"
                :error-messages="
                formErrors && formErrors.is_default
                  ? formErrors.is_default[0]
                  : ''
              "
            />
            <div>
              <v-btn block
                     dark
                     color="gifty"
                     v-if="!form.is_default"
                     class="rounded-lg mt-5"
                     depressed
                     @click="selectUsers"
              >
                <v-icon left>mdi-account-group-outline</v-icon>
                Affecter des Utilisateurs ({{ form.userIds.length }})
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
              <p
                  v-if="formErrors && formErrors.userIds"
                  class="error--text mt-0"
              >
                {{ formErrors.userIds[0] }}
              </p>
            </div>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                :loading="btnLoading"
                depressed
                type="submit"
            >
              Envoyer
              <v-icon right>mdi-send-outline</v-icon>
            </v-btn>
          </v-card-actions>

        </v-card>

      </form>

    </v-dialog>

    <SelectUsers ref="selectUsers" @selected="setUsers($event)"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

import SelectUsers from "@/views/publicities/components/SelectUsers.vue";

export default {
  components: {SelectUsers},
  data() {
    return {
      dialog: false,
      btnLoading: false,
      item: null,
      form: {
        image: "",
        is_default: false,
        userIds: [],
      },
      formErrors: {},
      language_tab: null,

      // date time picker
      send_day: "",
      send_hour: "",
      dateMenu: false,
      timeMenu: false,

      sendNow: false,
      noticeLoading: false,

      notif_model: null
    };
  },

  methods: {
    save() {
      this.formErrors = {};

      if (!this.sendNow && (!this.send_day || !this.send_hour)) {
        this.formErrors = {
          send_at: ["Ce champ est obligatoire"],
        };
        return;
      }

      this.btnLoading = true;

      let formData = new FormData();

      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      if (!this.sendNow) {
        formData.append("send_at", this.send_day + " " + this.send_hour);
      }

      // Method
      var url = "/v1/notices";
      if (this.item) {
        url += "/" + this.item.id;
      }
      // form data
      HTTP.post(url, formData)
          .then((res) => {
            this.successReult(res);
          })
          .catch((err) => {
            this.errorResult(err);
          });
    },

    successReult(res) {
      this.btnLoading = false;
      this.$emit("refresh");
      this.$successMessage = "Enregistrement avec succès";
      this.close();
    },
    errorResult(err) {
      console.log(err);

      this.btnLoading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        this.$errorMessage = "Veuillez verifiez le formulaire";
        this.formErrors = err.response.data.errors;
      } else {
        this.$errorMessage = "Une erreur s'est produite";
      }
    },
    open(item = null) {
      this.item = item;

      if (item) {
        this.setItem(item);
        this.showNotice(item.id);
      }

      this.dialog = true;
    },

    clone(item) {
      this.setItem(item);
      this.dialog = true;
    },

    setItem(item) {
      this.form = {
        title: item.title,
        title_ar: item.title_ar,
        body: item.body,
        body_ar: item.body_ar,
        is_default: item.is_default,
        filter: JSON.stringify(item.filter),
        userIds: Array.isArray(item.user_ids)
            ? item.user_ids.map((user) => user.id)
            : [],
        image: null,
      };

      this.send_day = item.send_day;
      this.send_hour = item.send_hour;

      if (!item.send_at) {
        this.all;
      }
    },

    close() {
      this.$refs.file.reset();
      this.form = {
        image: "",
        is_default: false,
        userIds: [],
      };
      this.item = null;
      this.dialog = false;
    },
    uploadFile(file) {
      console.log("hi");
      this.form.image = file;
    },
    setUsers(e) {
      this.form.userIds = e.users;
      this.form.filter = e.filter;
    },
    selectUsers() {
      if (this.item) {
        this.$refs.selectUsers.setUsers(
            this.item.users,
            this.form.userIds,
            this.item.filter
        );
      }
      this.$refs.selectUsers.open();
    },

    showNotice(id) {
      this.noticeLoading = true;
      HTTP.get("/v1/notices/" + id)
          .then((res) => {
            this.item.users = res.data.data.users;
            this.noticeLoading = false;
          })
          .catch((err) => {
            this.noticeLoading = false;
          });
    },
  },
};
</script>

<style scoped>
</style>